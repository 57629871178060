import React from "react";
import { Typography, List, ListItem, ListItemText } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Review = ({ shippingData }) => {
  const selector = useSelector((state) => state.cartReducer);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [total, setTotal] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0);

  const calculateSubtotal = () => {
    setShippingPrice(getShippingNumber(shippingData.shippingOption));
    const rawTotal = parseFloat(selector.checkoutToken.total.raw);
    const total = shippingPrice + rawTotal;
    setSubTotal(total);
    return total;
  };

  const getShippingNumber = (shippingId) => {
    if (shippingId === "ship_9BAmwJqYYleXdn") {
      return 7;
    } else {
      return 0;
    }
  };

  const calculateTaxes = (total) => {
    const result = total * 0.075;
    setTaxes(result.toFixed(2));
  };

  const calculateTotal = () => {
    const subTotalconverted = parseFloat(subTotal);
    const taxesConverted = parseFloat(taxes);
    setTotal(subTotalconverted + taxesConverted);
  };

  useEffect(() => {
    calculateSubtotal();
    // eslint-disable-next-line
  }, [taxes]);

  useEffect(() => {
    calculateTaxes(subTotal);
    // eslint-disable-next-line
  }, [subTotal]);

  useEffect(() => {
    calculateTotal();
    // eslint-disable-next-line
  }, [taxes]);

  if (selector.checkoutToken.line_items) {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          Order Summary
        </Typography>
        <List disablePadding>
          {selector.checkoutToken.line_items.map((product) => (
            <div key={product.id}>
              <ListItem style={{ padding: "10px 0" }} key={product.id}>
                <ListItemText
                  primary={product.name}
                  secondary={`Quantity: ${product.quantity}`}
                />
                <Typography variant="body2">
                  {product.line_total.formatted_with_symbol}
                </Typography>
              </ListItem>
            </div>
          ))}
          <ListItem style={{ padding: "10px 0" }} key="shipping">
            <ListItemText primary="Shipping" />
            <Typography variant="body2">
              {"$" + shippingPrice + ".00"}
            </Typography>
          </ListItem>
          <ListItem style={{ padding: "10px 0" }} key="taxes">
            <ListItemText primary="Tax" />
            <Typography variant="body2">{"$" + taxes}</Typography>
          </ListItem>
          <ListItem style={{ padding: "10px 0" }} key="total">
            <ListItemText primary="Total" />
            <Typography variant="subtitle1" style={{ fontweight: 700 }}>
              {"$" + total}
            </Typography>
          </ListItem>
        </List>
      </>
    );
  }
};

export default Review;
