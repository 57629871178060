import React from "react";
import useStyles from "./SocialStyles";
import { Button } from "@mui/material";
import { YouTube, Facebook, Instagram } from "@material-ui/icons";

const Social = () => {
  const styles = useStyles();

  return (
    <div>
      <div xs={12} className={styles.social}>
        <Button
          href="https://www.youtube.com/ivantheinspector"
          className={styles.social__buttons}
        >
          <YouTube className={styles.social__icons} />
        </Button>
        <Button
          href="https://www.facebook.com/ivantheinspector"
          className={styles.socialButtons}
        >
          <Facebook
            className={styles.social__icons}
            href="https://www.facebook.com/"
          />
        </Button>
        <Button
          href="https://www.instagram.com/ivantheinspector"
          className={styles.socialButtons}
        >
          <Instagram className={styles.social__icons} />
        </Button>
      </div>
    </div>
  );
};

export default Social;
