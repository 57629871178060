import React, { useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
  CssBaseline,
} from "@material-ui/core";
import ShippingForm from "./ShippingForm";
import BillingForm from "./BillingForm";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./checkoutStyles";
import useStepper from "../../hooks/useStepper";
import { useQuery } from "@tanstack/react-query";
import PaymentForm from "./PaymentForm";
import useCommerce from "../../hooks/useCommerce";
import Confirmation from "./Confirmation";
import cartActions from "../../actions/cartActions";

const steps = [
  "Shipping Information",
  "Billing Information",
  "Payment Details",
];

const Checkout = ({ cart, tokenTest, error, clearError }) => {
  const selector = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  const { fetchStates, generateCheckoutToken } = useCommerce();

  const { activeStep, nextStep } = useStepper();
  const [shippingData, setShippingData] = useState();
  const [billingData, setBillingData] = useState();

  const classes = useStyles();

  useQuery({
    queryKey: ["states"],
    queryFn: fetchStates,
  });

  const persistShippingData = (data) => {
    console.log(data);
    setShippingData(data);
    nextStep();
  };

  const persistBillingData = (data) => {
    console.log(data);
    setBillingData(data);
    nextStep();
  };

  useEffect(() => {
    const fetch = async () => {
      const checkoutTokenId = await generateCheckoutToken();
      if (checkoutTokenId) {
        console.log(checkoutTokenId);
        dispatch(cartActions.addCheckoutToken(checkoutTokenId));
      }
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  if (!selector.checkoutToken) {
    return <CircularProgress />;
  }

  return (
    <>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography variant="h4" align="center"></Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((step) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 ? (
          <ShippingForm
            cart={cart}
            tokenTest={tokenTest}
            nextStep={nextStep}
            persistShippingData={persistShippingData}
          />
        ) : (
          <></>
        )}
        {activeStep === 1 ? (
          <BillingForm
            cart={cart}
            tokenTest={tokenTest}
            nextStep={nextStep}
            persistBillingData={persistBillingData}
          />
        ) : (
          <></>
        )}
        {activeStep === 2 ? (
          <PaymentForm
            shippingData={shippingData}
            billingData={billingData}
            nextStep={nextStep}
          />
        ) : (
          <></>
        )}
        {activeStep === 3 ? (
          <Confirmation clearError={clearError} error={error} />
        ) : (
          <></>
        )}
      </Paper>
    </>
  );
};

export default Checkout;
