import { makeStyles } from "@material-ui/core";
import { ivanLightBlue, ivanBlue } from "../../constants/ivanColors";
import magnifyingGlassBlue from "../../assets/magnifyingGlassBlue.png";

export default makeStyles((theme) => ({
  videos__scroller: {
    height: 320,
  },
  videos__title: {
    marginTop: 0,
  },
  videos__thumbnails: {
    height: 300,
    width: 400,
  },
  scrollerPlaceholder: {
    display: "flex",
    color: "red",
    fontSize: 20,
  },
}));
