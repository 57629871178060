export const CART_REQUESTED = "CART_REQUESTED";
export const CART_RECEIVED = "CART_RECEIVED";
export const CART_FAILED = "CART_FAILED";
export const CART_REFRESH = 'CART_REFRESH';
export const ADD_CHECKOUT_TOKEN = 'ADD_CHECKOUT_TOKEN';
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_SHIRT_VARIANTS = 'ADD_SHIRT_VARIANTS';
export const ADD_COMBO_VARIANTS = 'ADD_COMBO_VARIANTS';
export const ADD_YOUTUBE_VIDEO_ARRAY = 'ADD_YOUTUBE_VIDEO_ARRAY';
export const ADD_SHIPPING_COUNTRIES = 'ADD_SHIPPING_COUNTRIES';
export const ADD_SHIPPING_DIVISIONS = 'ADD_SHIPPING_DIVISIONS';
export const ADD_SHIPPING_OPTIONS = 'ADD_SHIPPING_OPTIONS';