import { makeStyles } from "@material-ui/core/styles";
import { ivanBlue } from "../../constants/ivanColors";
import magnifyingGlassBlue from "../../assets/magnifyingGlassBlue.png";
import { withTheme } from "@emotion/react";

export default makeStyles((theme) => ({
  toolbar: {
    paddingTop: 15,
  },
  title: {
    textAlign: "center",
    border: "solid",
    background: ivanBlue,
    paddingBottom: 5,
    fontSize: 30,
    marginBottom: 10,
    borderRadius: 20,
  },
  emptyButton: {
    minWidth: "150px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5px",
    },
    [theme.breakpoints.up("xs")]: {
      marginRight: "20px",
    },
  },
  checkoutButton: {
    minWidth: "150px",
  },
  link: {
    textDecoration: "none",
  },
  cardDetails: {
    display: "flex",
    marginTop: "10%",
    width: "100%",
    justifyContent: "space-between",
  },
  backgroundImage: {
    backgroundImage: "url(" + magnifyingGlassBlue + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  subtotal: {
    background: "white",
    border: "2px solid black",
    fontSize: 25,
    borderRadius: 25,
    paddingLeft: 10,
    paddingRight: 10,
  },
  itemCard: {
    padding: 0,
  },
  emptyCartContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 500,
  },
  emptyCartText: {
    fontSize: 20,
    textAlign: "center",
    width: 500,
    marginTop: 50,
  },
  emptyCartButton: {
    marginTop: 50,
    backgroundColor: "white",
  },
  loadingScreen: {
    height: 500,
    display: "flex",
    alignContent: "center",
  },
}));
