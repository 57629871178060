import { makeStyles, alpha } from "@material-ui/core/styles";
import { ivanBlue } from "../../constants/ivanColors";

const drawerWidth = 0;

export default makeStyles((theme) => ({
  contactBox: {
    width: "100%",
    display: "flex",
    alignContent: "center",
  },
  formControl: {
    width: "100%",
    display: "block",
    margin: "auto",
    padding: 3,
  },
  submitButton: {
    backgroundColor: ivanBlue,
    color: "white",
    fontWeight: 800,
  },
}));
