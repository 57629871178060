import {
  CART_FAILED,
  CART_RECEIVED,
  CART_REQUESTED,
  ADD_CHECKOUT_TOKEN,
  ADD_ORDER,
  ADD_SHIRT_VARIANTS,
  ADD_COMBO_VARIANTS,
  ADD_YOUTUBE_VIDEO_ARRAY,
  ADD_SHIPPING_COUNTRIES,
} from "../constants/actionTypes";

const cartRequested = () => {
  return {
    type: CART_REQUESTED,
  };
};

const getCart = (cart) => {
  return {
    type: CART_RECEIVED,
    payload: cart,
  };
};

const cartFailed = () => {
  return {
    type: CART_FAILED,
  };
};

const addCheckoutToken = (checkoutToken) => {
  return {
    type: ADD_CHECKOUT_TOKEN,
    payload: checkoutToken,
  };
};

const addOrder = (order) => {
  return {
    type: ADD_ORDER,
    payload: order,
  };
};

const addShirtVariants = (data) => {
  return {
    type: ADD_SHIRT_VARIANTS,
    payload: data,
  };
};

const addComboVariants = (data) => {
  return {
    type: ADD_COMBO_VARIANTS,
    payload: data,
  };
};

const addTopThreeYoutubeVideos = (data) => {
  return {
    type: ADD_YOUTUBE_VIDEO_ARRAY,
    payload: data,
  };
};

const addShippingCountries = (data) => {
  return {
    type: ADD_SHIPPING_COUNTRIES,
    payload: data,
  };
};

export default {
  cartRequested,
  addCart: getCart,
  cartFailed,
  addCheckoutToken,
  addOrder,
  addShirtVariants,
  addComboVariants,
  addTopThreeYoutubeVideos,
  addShippingCountries,
};
