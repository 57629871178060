import { makeStyles } from "@material-ui/core";
import { ivanLightBlue, ivanBlue } from "../../constants/ivanColors";
import magnifyingGlassBlue from "../../assets/magnifyingGlassBlue.png";
import { FullscreenExit } from "@material-ui/icons";

export default makeStyles((theme) => ({
  // welcome element
  welcome: {
    backgroundImage: "url(" + magnifyingGlassBlue + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: 380,
  },
  welcome__block: {
    border: "black 4px solid",
    height: 310,
    width: 320,
    margin: "auto",
    background: ivanLightBlue,
    borderRadius: 25,
    position: "relative",
    top: 25,
  },
  welcome__logo: {
    position: "relative",
    paddingLeft: 10,
    paddingRight: 15,
    margin: "auto",
    display: "block",
    float: "left",
  },
  welcome__block__top: {
    display: "flex",
  },
  welcome__text: {
    display: "inline",
    fontSize: "20px",
    position: "relative",
    top: 10,
    boxSizing: "border-box",
    fontFamily: "Alphakind",
    padding: 10,
  },
  //youtube element
  youtube: {
    height: 50,
    width: 300,
    border: "2px solid black",
    display: "flex",
    justifyContent: "space-between",
    margin: "0 auto",
    borderRadius: 45,
    backgroundColor: ivanBlue,
    marginTop: 10,
  },
  youtube__icon: {
    fontSize: 32,
  },
  youtube__text: {
    fontSize: 16,
    fontFamily: "copperplate",
    color: "white",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
  },
  youtube__button: {
    alignItems: "center",
    margin: "auto",
    color: "#ff0c00",
    position: "relative",
  },
  welcome__logo: {
    display: "none",
  },

  [theme.breakpoints.up(600)]: {
    welcome__block: {
      width: 500,
      height: 300,
    },
    welcome__logo: {
      display: "inline-block",
      height: 150,
      padding: "10px 10px",
    },
    youtube: {
      marginTop: 20,
    },
  },
  [theme.breakpoints.down(500)]: {},
}));
