import { makeStyles } from '@material-ui/core/styles'
import { ivanBlue,ivanGreen } from '../../../constants/ivanColors'

export default makeStyles(() => ({
    root: {
        maxWidth: '100%',
        borderColor: ivanBlue,
        border: 'solid 3px'
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between'
    },
}))