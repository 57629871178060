import React from "react";
import { Typography, Button, Divider } from "@material-ui/core";
import {
  Elements,
  CardElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Review from "./Review";
import useCommerce from "../../hooks/useCommerce";
import { stripePromise } from "../../lib/stripe";
import { useDispatch } from "react-redux";
import cartActions from "../../actions/cartActions";
import { CircularProgress } from "@mui/material";

const PaymentForm = ({ shippingData, billingData, nextStep, backStep }) => {
  const selector = useSelector((state) => state.cartReducer);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { handleCaptureCheckout, refreshCart } = useCommerce(
    selector.cart.id,
    () => {},
    dispatch
  );
  const [localLoading, setLocalLoading] = useState(false);

  const handleSubmit = async (event, elements, stripe) => {
    setLocalLoading(true);
    event.preventDefault();

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      errorMessage(error.code);
    } else {
      let orderData = {
        line_items: selector.checkoutToken.line_items,
        customer: {
          firstname: shippingData.firstName,
          lastname: shippingData.lastName,
          email: shippingData.email,
        },
        shipping: {
          name: "primary",
          street: shippingData.address1,
          town_city: shippingData.city,
          county_state: shippingData.shippingSubdivision,
          postal_zip_code: shippingData.zip,
          country: "US",
        },
        billing: {
          name: "primary",
          street: billingData.address1,
          town_city: billingData.city,
          county_state: billingData.shippingSubdivision,
          postal_zip_code: billingData.zip,
          country: "US",
        },
        fulfillment: { shipping_method: shippingData.shippingOption },
        payment: {
          gateway: process.env.REACT_APP_PAYMENT_GATEWAY_KEY,
          stripe: {
            payment_method_id: paymentMethod.id,
          },
          ...(process.env.NODE_ENV === "development" && {
            card: {
              number: "4242424242424242",
              expiry_month: "02",
              expiry_year: "24",
              cvc: "123",
              postal_zip_code: "94107",
            },
          }),
        },
      };
      if (process.env.NODE_ENV === "development") {
        orderData = {
          line_items: selector.checkoutToken.line_items,
          customer: {
            firstname: "testFirstName",
            lastname: "testLastName",
            email: "testEmail@gmail.com",
          },
          shipping: {
            name: "primary",
            street: "shiptestStreet",
            town_city: "shiptestTownCity",
            county_state: "MN",
            postal_zip_code: "55313",
            country: "US",
          },
          billing: {
            name: "primary",
            street: "billtestStreet",
            town_city: "billtestTownCity",
            county_state: "MN",
            postal_zip_code: "55313",
            country: "US",
          },
          fulfillment: { shipping_method: shippingData.shippingOption },
          payment: {
            gateway: process.env.REACT_APP_PAYMENT_GATEWAY_KEY,
            stripe: {
              payment_method_id: paymentMethod.id,
            },
            ...(process.env.NODE_ENV === "development" && {
              card: {
                number: "4242424242424242",
                expiry_month: "02",
                expiry_year: "24",
                cvc: "123",
                postal_zip_code: "94107",
              },
            }),
          },
        };
      }

      console.log(orderData);
      const incomingOrder = await handleCaptureCheckout(
        selector.checkoutToken.id,
        orderData
      );

      dispatch(cartActions.addOrder(incomingOrder));
      refreshCart();

      localStorage.removeItem("cartId");
      dispatch(cartActions.addCart({}));
      dispatch(cartActions.addCheckoutToken({}));

      nextStep();
      setLocalLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const errorMessage = (error) => {
    setError(error);
    setOpen(true);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      ></IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={error}
        action={action}
      />
      <Review shippingData={shippingData} />
      <Divider />
      <Typography variant="h6" gutterBottom style={{ margin: "20px 0" }}>
        Payment method
      </Typography>
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <form onSubmit={(e) => handleSubmit(e, elements, stripe)}>
              <CardElement />
              <br /> <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button variant="outlined" onClick={backStep}>
                  Back
                </Button>
                {localLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!stripe}
                    color="primary"
                  >
                    Submit Payment
                  </Button>
                )}
              </div>
            </form>
          )}
        </ElementsConsumer>
      </Elements>
    </>
  );
};

export default PaymentForm;
