import React, { useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";

import Product from "./Product/Product";
import useStyles from "./storeStyles";
import { CssBaseline } from "@material-ui/core";
import { commerce } from "../../lib/commerce";
import { useEffect } from "react";

const Products = ({ onAddToCart }) => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchProducts() {
    const { data } = await commerce.products.list({
      include: "variant_groups",
    });
    console.log(data);
    const array = [];
    for (const key in data) {
      array.push(data[key]);
    }
    console.log(array);
    setProducts(array);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, []);

  let content = (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );

  if (!isLoading) {
    content = (
      <Grid container justifyContent="center" spacing={3}>
        {products.map((product) => (
          <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
            <Product product={product} onAddToCart={onAddToCart} />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <main className={classes.backgroundImage}>
      <CssBaseline />
      <div className={classes.toolbar} />
      {content}
    </main>
  );
};

export default Products;
