import React from "react";
import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import useStyles from "./styles";
import cartActions from "../../../actions/cartActions";
import { useDispatch } from "react-redux";
import { commerce } from "../../../lib/commerce";

const CartItem = ({ item }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const changeQuantityCall = async (mod) => {
    dispatch(cartActions.addCart(await commerceFetchCart(mod)));
  };
  const commerceFetchCart = async (mod) => {
    const newCart = await fetchCartPromise(mod);
    return newCart;
  };
  async function fetchCartPromise(mod) {
    const newMod = item.quantity + mod;
    const cart = await commerce.cart.update(item.id, { quantity: newMod });
    return cart;
  }

  const removeItemCall = async (mod) => {
    dispatch(cartActions.addCart(await fetchRemoveItem(mod)));
  };
  const fetchRemoveItem = async (mod) => {
    const newCart = await fetchItemRemovePromise(mod);
    return newCart;
  };
  async function fetchItemRemovePromise(mod) {
    const cart = await commerce.cart.update(item.id, { quantity: mod });
    return cart;
  }

  //is this a tshirt, if so display the size and color
  const TshirtLogicCheck = () =>
    item.name === "T-Shirt" || item.name === "Ivan combo pck" ? (
      <Card>
        <CardMedia
          image={item.image.url}
          alt={item.name}
          className={classes.media}
        />
        <CardContent className={classes.cardContent}>
          <Typography variant="h4">
            {item.variant.sku} {item.name}
          </Typography>
          <Typography variant="h5">
            {item.line_total.formatted_with_symbol}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <div className={classes.buttons}>
            <Button
              type="button"
              size="small"
              onClick={() => changeQuantityCall(-1)}
            >
              -
            </Button>
            <Typography>{item.quantity}</Typography>
            <Button
              type="button"
              size="small"
              onClick={() => changeQuantityCall(1)}
            >
              +
            </Button>
          </div>
          <Button
            variant="contained"
            type="button"
            color="secondary"
            onClick={() => removeItemCall(0)}
          >
            Remove
          </Button>
        </CardActions>
      </Card>
    ) : (
      <Card>
        <CardMedia
          image={item.image.url}
          alt={item.name}
          className={classes.media}
        />
        <CardContent className={classes.cardContent}>
          <Typography variant="h4">{item.name}</Typography>
          <Typography variant="h5">
            {item.line_total.formatted_with_symbol}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <div className={classes.buttons}>
            <Button
              type="button"
              size="small"
              onClick={() => changeQuantityCall(-1)}
            >
              -
            </Button>
            <Typography>{item.quantity}</Typography>
            <Button
              type="button"
              size="small"
              onClick={() => changeQuantityCall(1)}
            >
              +
            </Button>
          </div>
          <Button
            variant="contained"
            type="button"
            color="secondary"
            onClick={() => removeItemCall(0)}
          >
            Remove
          </Button>
        </CardActions>
      </Card>
    );

  return <TshirtLogicCheck />;
};

export default CartItem;
