import React, { useEffect, useState } from "react";
import useStyles from "./YoutubeScrollerStyles";
import { useSnapCarousel } from "react-snap-carousel";
import { CircularProgress } from "@material-ui/core";
import useYoutube from "../../hooks/useYoutube";

const YoutubeScroller = () => {
  const styles = useStyles();
  const { scrollRef } = useSnapCarousel();
  const [videos, setVideos] = useState([]);
  const {
    videos: data,
    videosAreLoading: isLoading,
    getYoutubeVideoUrl,
    checkForUndefinedAndShuffleAndSetVideos,
  } = useYoutube();

  useEffect(() => {
    if (data && !isLoading) {
      console.log(data.items);
      checkForUndefinedAndShuffleAndSetVideos(data.items, setVideos);
    }
    // eslint-disable-next-line
  }, [data]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (videos) {
    return (
      <div className={styles.videos__scroller}>
        <div
          ref={scrollRef}
          style={{
            display: "flex",
            overflow: "auto",
            scrollSnapType: "x mandatory",
          }}
        >
          {videos.map((video) => (
            <div
              key={video.etag}
              href={getYoutubeVideoUrl(video)}
              target="_blank"
              style={{
                flexShrink: 0,
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                paddingLeft: 0,
                width: 400,
              }}
            >
              <a href={getYoutubeVideoUrl(video)} target="_blank">
                <img
                  src={video.snippet.thumbnails.high.url}
                  className={styles.videos__thumbnails}
                  alt="ivan"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default YoutubeScroller;
