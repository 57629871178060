import { commerce } from "../lib/commerce";
import cartActions from "../actions/cartActions";
import { useSelector } from "react-redux";

const useCommerce = (cartId, setErrorMessage, dispatch) => {
  const selector = useSelector((state) => state.cartReducer);
  const initialSetup = async () => {
    const cart = await getOrCreateCart(cartId);

    const shirtVariants = await commerce.products.getVariants(
      "prod_aZWNoyanMYo80J",
    );

    const comboVariants = await commerce.products.getVariants(
      "prod_Kvg9l68Dkpo1bB",
    );

    return {
      cart,
      shirtVariants,
      comboVariants,
    };
  };

  const getOrCreateCart = async (cartId) => {
    let cart = "";
    if (!cartId || cartId === "undefined") {
      cart = await commerce.cart.retrieve();
      console.log("new cart created", cart);
    } else {
      cart = await commerce.cart.retrieve(cartId);
      console.log("previous cart retrieved", cart);
    }
    return cart;
  };

  const convertVariantObjectToArray = (object) => {
    const returnArray = [];
    for (let i = 0; i < object.data.length; i++) {
      returnArray.push(object.data[i]);
    }
    return returnArray;
  };

  const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
    try {
      const incomingOrder = await commerce.checkout.capture(
        checkoutTokenId,
        newOrder,
      );
      return incomingOrder;
    } catch (error) {
      setErrorMessage(error.data.error.message);
    }
  };

  const refreshCart = async () => {
    const newCart = await commerce.cart.refresh();
    dispatch(cartActions.addCart(newCart));
  };

  const clearError = () => {
    setErrorMessage("");
  };

  const emptyCart = async () => {
    const newCart = await commerce.cart.empty();
    dispatch(cartActions.addCart(newCart));
  };

  const fetchStates = async () => {
    const { subdivisions } =
      await commerce.services.localeListSubdivisions("US");
    return subdivisions;
  };

  const fetchShippingOptions = async () => {
    const options = await commerce.checkout.getShippingOptions(
      selector.checkoutToken.id,
      { country: "AS" },
    );
    return options;
  };

  const generateCheckoutToken = async () => {
    if (!localStorage.getItem("cartId")) {
      getOrCreateCart();
      return;
    }
    if (selector.checkoutToken.id === undefined) {
      const checkoutToken = await commerce.checkout.generateTokenFrom(
        "cart",
        localStorage.getItem("cartId"),
      );
      console.log("returning new checkoutToken ", checkoutToken);
      return checkoutToken;
    } else {
      console.log(
        "cart id of " +
          localStorage.getItem("cartId") +
          " exists and checkoutToken " +
          selector.checkoutToken.id +
          " exists",
      );
    }
  };

  const addItem = async (productId, quantity, variant) => {
    const newCart = await commerce.cart.add(productId, quantity);
  };

  return {
    initialSetup,
    convertVariantObjectToArray,
    handleCaptureCheckout,
    clearError,
    emptyCart,
    fetchStates,
    fetchShippingOptions,
    generateCheckoutToken,
    refreshCart,
    getOrCreateCart,
  };
};

export default useCommerce;
