import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import Store from "./components/Store/Store";
import Cart from "./components/Cart/Cart";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Checkout from "./components/CheckoutForm/Checkout";
import { useDispatch } from "react-redux";
import cartActions from "./actions/cartActions";
import Homepage from "./components/Homepage/Homepage";
import Contact from "./components/Contact/Contact";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import useCommerce from "./hooks/useCommerce";

function App() {
  const queryClient = new QueryClient();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const {
    initialSetup,
    convertVariantObjectToArray,
    handleCaptureCheckout,
    clearError,
  } = useCommerce(localStorage.getItem("cartId"), setErrorMessage, dispatch);

  const transformShirtVariants = (shirtVariantsArray) => {
    shirtVariantsArray.forEach((shirt) => {
      const size = shirt.sku.substring(0, shirt.sku.indexOf(" "));
      const color = shirt.sku.substring(shirt.sku.indexOf(" ") + 1);
      shirt.size = size;
      shirt.color = color;
    });
    return shirtVariantsArray;
  };

  useEffect(() => {
    const fetch = async () => {
      const { cart, shirtVariants, comboVariants } = await initialSetup();
      localStorage.setItem("cartId", cart.id);
      dispatch(cartActions.addCart(cart));

      const shirtVariantsArray = convertVariantObjectToArray(shirtVariants);
      const shirts = transformShirtVariants(shirtVariantsArray);
      dispatch(cartActions.addShirtVariants(shirts));

      const comboVariantsArray = convertVariantObjectToArray(comboVariants);
      const combo = transformShirtVariants(comboVariantsArray);
      dispatch(cartActions.addComboVariants(combo));
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/shop" element={<Store />} />
            <Route path="/cart" element={<Cart />} />
            <Route
              path="/checkout"
              element={
                <Checkout
                  onCapturecheckout={handleCaptureCheckout}
                  error={errorMessage}
                  clearError={clearError}
                />
              }
            />
            <Route path="/contact" element={<Contact />}></Route>
          </Routes>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
