import { makeStyles } from "@material-ui/core";
import { ivanLightBlue, ivanBlue } from "../../constants/ivanColors";
import magnifyingGlassBlue from "../../assets/magnifyingGlassBlue.png";

export default makeStyles((theme) => ({
  social: {
    border: "2px solid black",
    borderRadius: 25,
    width: 200,
    background: ivanLightBlue,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: 10,
    marginBottom: 5,
  },
  social__icons: {
    fontSize: "300%",
    padding: 1,
    color: "black",
  },
  [theme.breakpoints.up(1221)]: {
    social: {
      position: "absolute",
      top: 150,
      left: 25,
      height: 180,
      width: 70,
      display: "block",
    },
    social__buttons: {},
    social__icons: {},
  },
}));
