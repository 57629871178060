import React from "react";
import useStyles from "./WelcomeStyles";
import ivanLogo from "../../assets/ivanLogo.png";
import { Button, CircularProgress } from "@material-ui/core";
import { YouTube } from "@material-ui/icons";
import useYoutubeData from "../../hooks/useYoutubeData";

const Welcome = () => {
  const styles = useStyles();
  const { data, isLoading, isError, formatViewData } = useYoutubeData();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>oops something went wrong</div>;
  }

  if (data) {
    const youtubeData = data.items[0].statistics;
    return (
      <div className={styles.welcome}>
        <div className={styles.welcome__block}>
          <div className={styles.welcome__block__top}>
            <img
              className={styles.welcome__logo}
              src={ivanLogo}
              alt="Ivan Logo"
            />
            <div className={styles.welcome__text}>
              Hi, Friend! <br />
              I'm Ivan the Inspector, won't you come along and learn with me?{" "}
              <br />
              <br />
              Check out my YouTube Channel or the Merch Store.
            </div>
          </div>
          {data ? (
            <div className={styles.youtube}>
              <Button
                href="https://www.youtube.com/ivantheinspector"
                className={styles.youtube__button}
              >
                <YouTube className={styles.youtube__icon} />
              </Button>
              <div className={styles.youtube__text}>
                Videos: {youtubeData.videoCount}
              </div>
              <div className={styles.youtube__text}>
                Subs: {youtubeData.subscriberCount}
              </div>
              <div className={styles.youtube__text}>
                Views: {formatViewData(youtubeData.viewCount)}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }

  //   let loadedContent = <CircularProgress />;
  //
  //   if (!isLoading && !renderPlaceholder) {
  //     loadedContent = (
  //       <div className={styles.youtube}>
  //         <Button
  //           href="https://www.youtube.com/ivantheinspector"
  //           className={styles.youtube__button}
  //         >
  //           <YouTube className={styles.youtube__icon} />
  //         </Button>
  //         <div className={styles.youtube__text}>
  //           Videos: {youtubeData.videoCount}
  //         </div>
  //         <div className={styles.youtube__text}>
  //           Subs: {youtubeData.subscriberCount}
  //         </div>
  //         <div className={styles.youtube__text}>
  //           Views: {formatViewData(youtubeData.viewCount)}
  //         </div>
  //       </div>
  //     );
  //   }
  //
  //   if (renderPlaceholder) {
  //     loadedContent = (
  //       <div className={styles.youtube}>
  //         <Button
  //           href="https://www.youtube.com/ivantheinspector"
  //           className={styles.youtube__button}
  //         >
  //           <YouTube className={styles.youtube__icon} />
  //         </Button>
  //       </div>
  //     );
  //   }
  //
  //   return (
  //     <div className={styles.welcome}>
  //       <div className={styles.welcome__block}>
  //         <div className={styles.welcome__block__top}>
  //           <img
  //             className={styles.welcome__logo}
  //             src={ivanLogo}
  //             alt="Ivan Logo"
  //           />
  //           <div className={styles.welcome__text}>
  //             Hi, Friend! <br />
  //             I'm Ivan the Inspector, won't you come along and learn with me?{" "}
  //             <br />
  //             <br />
  //             Check out my YouTube Channel or the Merch Store.
  //           </div>
  //         </div>
  //         {loadedContent}
  //       </div>
  //     </div>
  //   );
};

export default Welcome;
