import { makeStyles } from "@material-ui/core";
import { ivanLightBlue, ivanBlue } from "../../constants/ivanColors";
import magnifyingGlassBlue from "../../assets/magnifyingGlassBlue.png";
import { NoEncryption } from "@material-ui/icons";

export default makeStyles((theme) => ({
  contact__text: {
    fontSize: 20,
    textAlign: "center",
    padding: 4,
  },

  footer: {
    display: "block",
    margin: "auto",
    textAlign: "center",
  },
  cameo__container: {
    display: "flex",
    justifyContent: "center",
  },
  cameo: {
    display: "flex",
    maxWidth: 750,
    justifyContent: "center",
    border: "4px solid black",
    borderRadius: "15px",
  },
  cameo__logo: {
    height: 150,
    width: 150,
  },
  cameo__text: {
    display: "inline",
    fontSize: "20px",
    position: "relative",
    boxSizing: "border-box",
    fontFamily: "Alphakind",
    backgroundColor: ivanLightBlue,
    padding: "10px",
    borderTopRightRadius: "13px",
    borderBottomRightRadius: "13px",
    color: "black",
  },
  cameo__anchor: {
    textDecoration: "none",
  },
  [theme.breakpoints.down(550)]: {
    cameo__text: {
      fontSize: "15px",
    },
  },
  [theme.breakpoints.up(1000)]: {
    contact__photo: {
      width: 500,
      border: "gray 4px solid",
      display: "inline-block",
      margin: "auto",
    },
    contact__form: {
      display: "inline-block",
      width: 500,
      verticalAlign: "top",
    },
    contact: {
      height: 360,
      textAlign: "center",
      marginTop: 25,
    },
  },
  [theme.breakpoints.down(1000)]: {
    welcome: {
      height: 320,
    },

    contact__photo: {
      width: "80%",
      border: "gray 4px solid",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  [theme.breakpoints.down(500)]: {
    backgroundImage: {
      height: 600,
    },

    contact__photo: {
      width: "100%",
      border: "gray 4px solid",
      margin: "auto",
    },
  },
  [theme.breakpoints.up(1221)]: {
    social: {
      position: "absolute",
      top: 150,
      left: 25,
      height: 180,
      width: 70,
      display: "block",
    },
    social__buttons: {},
    social__icons: {},
  },
}));
