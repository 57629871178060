import React from "react";
import useStyles from "./contactStyles";
import { Button, CssBaseline, FormControl, TextField } from "@material-ui/core";
import emailjs from "emailjs-com";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Contact = () => {
  const classes = useStyles();

  const SERVICE_ID = "service_5rj50jq";
  const TEMPLATE_ID = "template_e73z2jc";
  const USER_ID = "C_jK2QIM4s7Q8G8AR";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [setError] = useState(false);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.message === ""
    ) {
      setError(true);
      return;
    }
    emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID).then(
      (result) => {
        console.log(result.text);
        setOpen(true);
        setName("");
        setEmail("");
        setMessage("");
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  let formData = {
    name: name,
    email: email,
    message: message,
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <main className={classes.contactBox} spacing={3} justify="text-center">
        <Grid item>
          <FormControl
            className={classes.formControl}
            onSubmit={handleOnSubmit}
            id="form"
          >
            <TextField
              required
              onChange={handleEmailChange}
              id="email"
              label="email"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              required
              onChange={handleNameChange}
              id="name"
              label="name"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              required
              onChange={handleMessageChange}
              id="message"
              label="message"
              variant="outlined"
              multiline
              minRows={4}
              maxRows={8}
              margin="normal"
              fullWidth
            />
            <Button
              className={classes.submitButton}
              onClick={handleOnSubmit}
              variant="contained"
              type="submit"
            >
              Submit
            </Button>
          </FormControl>
        </Grid>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Message sent successfully!
          </Alert>
        </Snackbar>
      </main>
    </>
  );
};

export default Contact;
