import React, { useState } from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import FormInput from "./FormInput";
import { Link } from "react-router-dom";
import useCommerce from "../../hooks/useCommerce";
import { useQuery } from "@tanstack/react-query";

const BillingForm = ({ persistBillingData }) => {
  const methods = useForm();
  const [shippingSubdivision, setShippingSubdivision] = useState("");
  const [shippingOptions] = useState([]);
  const { fetchStates } = useCommerce();

  const { data: states, isLoading: statesIsLoading } = useQuery({
    queryKey: ["states"],
    queryFn: fetchStates,
  });

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Shipping Address
      </Typography>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) =>
            persistBillingData({
              ...data,
              shippingSubdivision,
            })
          )}
        >
          <Grid container spacing={3}>
            <FormInput required name="firstName" label="First name" />
            <FormInput required name="lastName" label="Last name" />
            <FormInput required name="address1" label="Address line 1" />
            <FormInput required name="email" label="Email" />
            <FormInput required name="city" label="City" />
            <FormInput required name="zip" label="Zip / Postal code" />
            {statesIsLoading || !shippingOptions ? (
              <CircularProgress />
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <InputLabel>Shipping State</InputLabel>
                  <Select
                    value={shippingSubdivision ? shippingSubdivision : ""}
                    fullWidth
                    onChange={(e) => setShippingSubdivision(e.target.value)}
                  >
                    {Object.entries(states)
                      .map(([code, name]) => ({ id: code, label: name }))
                      .map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </>
            )}
          </Grid>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button component={Link} variant="outlined" to="/cart">
              Back to Cart
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Next
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default BillingForm;
