import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import cartActions from "../../../actions/cartActions";
import useStyles from "./productStyles";
import { commerce } from "../../../lib/commerce";
import useCommerce from "../../../hooks/useCommerce";
import { useEffect } from "react";

const Product = ({ product }) => {
  const selector = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  const [color, setColor] = useState();
  const [size, setSize] = useState();
  const [availableColors, setAvailableColors] = useState();
  const [allSizes, setAllSizes] = useState();
  const {} = useCommerce();

  const classes = useStyles();

  // addItem calls fetchNewCart which calls fetchCartPromise.  It adds the new item and persists the new store.
  const addItemCall = async () => {
    dispatch(cartActions.addCart(await fetchNewCart()));
  };

  const fetchNewCart = async () => {
    const newCart = await addItemWithVariants();
    return newCart;
  };

  /**
   * this funciton has the logic to check if there is a variant, adds the product with our without it
   * @returns New Cart Object
   */
  async function addItemWithVariants() {
    const productId = product.id;
    const quantity = 1;
    const variant = getVariantId(productId);
    let newCart = "";
    if (!variant) {
      newCart = await commerce.cart.add(productId, quantity);
    } else {
      newCart = await commerce.cart.add(productId, quantity, variant);
    }
    return newCart;
  }

  /**
   * This looks up the variants for either shirts or combos in the Redux Store
   * @returns Variant Id, or 'none' if there isn't one
   */
  const getVariantId = (productId) => {
    const options = concatenateSizeAndColor(size, color);
    let newId = "";
    if (productId === "prod_aZWNoyanMYo80J") {
      try {
        newId = selector.shirtVariants.find((x) => x.sku === options).id;
      } catch {
        return null;
      }
    } else {
      try {
        newId = selector.comboVariants.find((x) => x.sku === options).id;
      } catch {
        return null;
      }
    }
    return newId;
  };

  const concatenateSizeAndColor = (size, color) => {
    return size + " " + color;
  };

  const changeColor = (event) => {
    setColor(event.target.value);
  };

  const changeSize = (event) => {
    setSize(event.target.value);
  };

  useEffect(() => {
    if (size) {
      checkAvailableColors();
    }
  }, [size]);

  const filterAllSizesAndColors = (shirts) => {
    const allSizes = [...new Set(shirts.map((item) => item.size))];
    setAllSizes(allSizes);
  };

  useEffect(() => {
    if (selector.shirtVariants && selector.comboVariants) {
      filterAllSizesAndColors(selector.shirtVariants, selector.comboVariants);
    }
  }, [selector.shirtVariants, selector.comboVariants]);

  //TODO: for next time, start here and try to figure out how to filter to only show the colors that have inventory

  let checkAvailableColors = () => {
    const colors = [];
    selector.shirtVariants.map((item) => {
      if (item.size == size && item.inventory > 0) {
        colors.push(item.color);
      }
    });
    console.log(colors);
    setAvailableColors(colors);
  };

  const ColorChoice = () =>
    product.name === "T-Shirt" || product.name === "Ivan Combo Pack" ? (
      <>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="size">Size</InputLabel>
          {allSizes ? (
            <Select
              labelId="size"
              id="demosize"
              value={size}
              label="size"
              onChange={changeSize}
              defaultValue=""
            >
              {allSizes.map((item, key) => (
                <MenuItem value={item} key={key}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <CircularProgress />
          )}
        </FormControl>
        {size && availableColors ? (
          <FormControl variant="filled" fullWidth>
            <InputLabel id="color">Color</InputLabel>
            <Select
              labelId="color"
              id="demoColor"
              value={color}
              label="color"
              onChange={changeColor}
              defaultValue=""
            >
              {availableColors.map((item, key) => (
                <MenuItem value={item} key={key}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <></>
        )}
      </>
    ) : (
      <></>
    );

  const AddCartButtonLogic = () =>
    (product.name === "T-Shirt" && color === "color") ||
    (product.name === "T-Shirt" && size === "size") ? (
      <>
        <IconButton aria-label="Add to Cart" onClick={addItemCall} disabled>
          <AddShoppingCart />
        </IconButton>
      </>
    ) : (
      <IconButton aria-label="Add to Cart" onClick={addItemCall}>
        <AddShoppingCart />
      </IconButton>
    );

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={product.image.url}
        title={product.name}
      />
      <CardContent>
        <div className={classes.cardContent}>
          <Typography variant="h5" gutterBottom>
            {product.name}
          </Typography>
          <Typography variant="h5" gutterBottom>
            {product.price.formatted_with_symbol}
          </Typography>
        </div>
        <Typography
          dangerouslySetInnerHTML={{ __html: product.description }}
          variant="body2"
          color="textSecondary"
        />
      </CardContent>
      <CardActions disableSpacing className={classes.cardActions}>
        <ColorChoice />
        <AddCartButtonLogic />
      </CardActions>
    </Card>
  );
};

export default Product;
