import React, { useState, useEffect } from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import FormInput from "./FormInput";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useCommerce from "../../hooks/useCommerce";
import { useQuery } from "@tanstack/react-query";
import cartActions from "../../actions/cartActions";

const ShippingForm = ({ persistShippingData }) => {
  const selector = useSelector((state) => state.cartReducer);
  const methods = useForm();
  const [shippingCountry] = useState("");
  const [shippingSubdivision, setShippingSubdivision] = useState("");
  const [shippingOption, setShippingOption] = useState("");
  const { fetchStates } = useCommerce();
  const dispatch = useDispatch();
  const { generateCheckoutToken } = useCommerce(
    localStorage.getItem("cartId"),
    () => {},
    dispatch
  );

  const { data: states, isLoading: statesIsLoading } = useQuery({
    queryKey: ["states"],
    queryFn: fetchStates,
  });

  console.log(selector);

  useEffect(() => {
    const fetch = async () => {
      const checkoutTokenId = await generateCheckoutToken();
      if (checkoutTokenId) {
        console.log(checkoutTokenId);
        dispatch(cartActions.addCheckoutToken(checkoutTokenId));
      }
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Shipping Address
      </Typography>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) =>
            persistShippingData({
              ...data,
              shippingCountry,
              shippingSubdivision,
              shippingOption,
            })
          )}
        >
          <Grid container spacing={3}>
            <FormInput required name="firstName" label="First name" />
            <FormInput required name="lastName" label="Last name" />
            <FormInput required name="address1" label="Address line 1" />
            <FormInput required name="email" label="Email" />
            <FormInput required name="city" label="City" />
            <FormInput required name="zip" label="Zip / Postal code" />
            {statesIsLoading ||
            selector.checkoutToken === "undefined" ||
            !states ? (
              <CircularProgress />
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <InputLabel>Shipping State</InputLabel>
                  <Select
                    value={shippingSubdivision ? shippingSubdivision : ""}
                    fullWidth
                    onChange={(e) => setShippingSubdivision(e.target.value)}
                  >
                    {states ? (
                      Object.entries(states)
                        .map(([code, name]) => ({ id: code, label: name }))
                        .map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.label}
                          </MenuItem>
                        ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel>Shipping Options</InputLabel>
                  <Select
                    value={shippingOption ? shippingOption : ""}
                    fullWidth
                    onChange={(e) => setShippingOption(e.target.value)}
                  >
                    {selector.checkoutToken.shipping_methods ? (
                      selector.checkoutToken.shipping_methods
                        .map((sO) => ({
                          id: sO.id,
                          label: `${sO.description} - (${sO.price.formatted_with_symbol})`,
                        }))
                        .map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.label}
                          </MenuItem>
                        ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </Grid>
              </>
            )}
          </Grid>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button component={Link} variant="outlined" to="/cart">
              Back to Cart
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Next
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default ShippingForm;
