import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import { ShoppingCart } from "@material-ui/icons";
import useStyles from "./navbarStyles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import wordscriptCropped from "../../assets/wordscriptCropped.png";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAtom } from "jotai";
import { loadingAtom } from "../../store/jotai";
import { CircularProgress } from "@mui/material";

const Navbar = () => {
  // const pages = ["home", "shop", "about me"];
  const pages = [
    { name: "Home", path: "/" },
    { name: "Shop", path: "/shop" },
  ];

  const [loading, setLoading] = useAtom(loadingAtom);

  const classes = useStyles();
  const selector = useSelector((state) => state.cartReducer);
  let badgeNumber = selector.cart.total_items;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              alignItems: "center",
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
            className={classes.navbarContainer}
          >
            <IconButton
              size="medium"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              className={classes.menuIconbutton}
            >
              <MenuIcon />
            </IconButton>
            <Link to={"/"}>
              <img src={wordscriptCropped} height="40" />
            </Link>
            <div className={classes.button}>
              <IconButton
                component={Link}
                to="/cart"
                aria-label="Show cart items"
                color="inherit"
              >
                <Badge
                  overlap="rectangular"
                  badgeContent={badgeNumber}
                  color="secondary"
                >
                  <ShoppingCart />
                </Badge>
              </IconButton>
            </div>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography
                    component={Link}
                    to={page.path}
                    className={classes.menuIconItem}
                  >
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="navbar-left">
              <Link to={"/"}>
                <img src={wordscriptCropped} height="40" />
              </Link>
            </div>
            <div className={classes.navbarRight}>
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    display: "block",
                    fontFamily: "Alphakind",
                    padding: "0 20px",
                  }}
                  component={Link}
                  to={page.path}
                  className={classes.menuIconItem}
                >
                  {page.name}
                </Button>
              ))}
              {loading ? (
                <CircularProgress />
              ) : (
                <div className={classes.button}>
                  <IconButton
                    component={Link}
                    to="/cart"
                    aria-label="Show cart items"
                    color="inherit"
                  >
                    <Badge
                      overlap="rectangular"
                      badgeContent={badgeNumber}
                      color="secondary"
                    >
                      <ShoppingCart />
                    </Badge>
                  </IconButton>
                </div>
              )}
            </div>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{
                mt: "45px",
                justifyContent: "flexStart",
                alignItems: "center",
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            ></Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
