import { makeStyles } from "@material-ui/core/styles";
import magnifyingGlassBlue from "../../assets/magnifyingGlassBlue.png";

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  backgroundImage: {
    backgroundImage: "url(" + magnifyingGlassBlue + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: theme.spacing(3),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
  },
}));
