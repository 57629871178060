import React from "react";
import useStyles from "./homepageStyles";
import { useEffect } from "react";
import { CssBaseline } from "@material-ui/core";
import ivanPhoto from "../../assets/IvanPhoto.png";
import Contact from "../Contact/Contact";
import "../../index.css";
import Welcome from "./Welcome";
import YoutubeScroller from "./YoutubeScroller";
import Social from "../Navbar/Social";
import useCommerce from "../../hooks/useCommerce";
import cameoLogo from "../../assets/cameo_logo.png";

const Homepage = () => {
  const { getOrCreateCart } = useCommerce(localStorage.getItem("cartId"));

  useEffect(() => {
    const fetchOrGetCart = async () => {
      const cart = await getOrCreateCart();
      return cart;
    };
    fetchOrGetCart();
    // eslint-disable-next-line
  }, []);
  const styles = useStyles();

  return (
    <>
      <CssBaseline />
      <Welcome />
      {process.env.NODE_ENV === "production" ? (
        <YoutubeScroller />
      ) : (
        <>Youtube scroller development placeholder</>
      )}
      <hr width="1200"></hr>
      <br />
      <div>
        <a
          class={styles.cameo__anchor}
          href={
            process.env.NODE_ENV === "production"
              ? "https://www.cameo.com/ivantheinspector"
              : ""
          }
        >
          <div class={styles.cameo__container}>
            <div class={styles.cameo}>
              <img src={cameoLogo} class={styles.cameo__logo}></img>
              <div class={styles.cameo__text}>
                Interested in getting a personalized Ivan video for your little
                to celebrate a birthday, holiday, or accomplishment? Check out
                Cameo!
              </div>
            </div>
          </div>
        </a>
        <div className={styles.contact__text}>
          In addition to our YouTube channel, we provide local concerts with
          lots of music and fun!
          <br />
          Get in touch below and find out how Ivan can make your event even
          better!
        </div>
        <div className={styles.contact}>
          <img className={styles.contact__photo} src={ivanPhoto} alt="Ivan" />
          <div className={styles.contact__form}>
            <Contact />
          </div>
        </div>
      </div>
      <Social />
      <footer className={styles.footer}>
        <hr></hr>
        Ivan the Inspector © {new Date().getFullYear()}
        <hr></hr>
      </footer>
    </>
  );
};

export default Homepage;
