import { useQuery } from "@tanstack/react-query";

const useYoutubeData = () => {
  const { data, isLoading, isError } = useQuery({
    queryFn: getYoutubeData,
    queryKey: ["youtubeData"],
  });

  async function getYoutubeData() {
    try {
      const url =
        "https://www.googleapis.com/youtube/v3/channels?part=statistics&id=UCDtDq83SS2k7v74iqoyoDgw&key=" +
        process.env.REACT_APP_YOUTUBE_KEY;
      const response = await fetch(url, {
        headers: {
          "Content-type": "application/json",
        },
        method: "GET",
      });
      const jsonResponse = await response.json();
      console.log(jsonResponse);
      return jsonResponse;
    } catch (error) {
      console.log("Something went wrong", error);
    }
  }
  const formatViewData = (number) => {
    return Intl.NumberFormat("en-US").format(number);
  };
  return {
    data,
    isLoading,
    isError,
    formatViewData,
  };
};

export default useYoutubeData;
