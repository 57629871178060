import { useState } from "react";

const useStepper = () => {
  // step 0 shipping, 1 billing, 2, summary and credit card
  const [activeStep, setActiveStep] = useState(0);
  const minimumStep = 0;
  const maximumStep = 3;

  const nextStep = () => {
    if (activeStep < maximumStep) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const backStep = () => {
    if (activeStep > minimumStep) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  return { activeStep, setActiveStep, nextStep, backStep };
};
export default useStepper;
