import { useQuery } from "@tanstack/react-query";

const useYoutube = () => {
  const {
    data: videos,
    isLoading: videosAreLoading,
    isError: videosError,
  } = useQuery({
    queryFn: getYoutubeVideos,
    queryKey: ["youtubeVideos"],
  });

  async function getYoutubeVideos() {
    try {
      const url =
        "https://www.googleapis.com/youtube/v3/playlistItems?key=" +
        process.env.REACT_APP_YOUTUBE_KEY +
        "&playlistId=PLC5Br4wpw4UowOy6MmC4rdngkbx3avPAl&part=contentDetails,id,snippet,status&maxResults=50";
      const response = await fetch(url, {
        headers: {
          "Content-type": "application/json",
        },
        method: "GET",
      });
      const jsonResponse = await response.json();
      console.log(jsonResponse);
      return jsonResponse;
    } catch (error) {
      console.log("Something went wrong", error);
    }
  }

  const getYoutubeVideoUrl = (video) => {
    const url =
      "https://www.youtube.com/watch?v=" +
      video.id.videoId +
      "&ab_channel=IvanTheInspector-EducationalVideosforKids";
    return url;
  };

  const checkForUndefinedAndShuffleAndSetVideos = (videos, setVideos) => {
    const newVideos = [];
    videos.map((video) => {
      try {
        if (video.snippet.thumbnails.high.url) {
          newVideos.push(video);
        }
      } catch (err) {}
    });

    const shuffledArray = newVideos.sort((a, b) => 0.5 - Math.random());
    setVideos(shuffledArray);
  };

  return {
    videos,
    videosAreLoading,
    videosError,
    getYoutubeVideoUrl,
    checkForUndefinedAndShuffleAndSetVideos,
  };
};

export default useYoutube;
