import {
  CART_FAILED,
  CART_RECEIVED,
  CART_REQUESTED,
  ADD_CHECKOUT_TOKEN,
  ADD_ORDER,
  ADD_COMBO_VARIANTS,
  ADD_SHIRT_VARIANTS,
  ADD_YOUTUBE_VIDEO_ARRAY,
  ADD_SHIPPING_COUNTRIES,
  ADD_SHIPPING_DIVISIONS,
  ADD_SHIPPING_OPTIONS,
} from "../../constants/actionTypes";

const initialCartState = {
  cart: {},
  cartLoaded: true,
  checkoutToken: {},
  order: {},
  topThreeVideos: [],
};

const cartReducer = (state = initialCartState, action) => {
  switch (action.type) {
    case CART_REQUESTED:
      return Object.assign({}, state, { cartLoaded: false });
    case CART_RECEIVED:
      return Object.assign({}, state, {
        cart: action.payload,
        cartLoaded: true,
      });
    case CART_FAILED:
      return Object.assign({}, state, {
        cartLoaded: true,
        error: action.payload,
      });
    case ADD_CHECKOUT_TOKEN:
      return Object.assign({}, state, { checkoutToken: action.payload });
    case ADD_ORDER:
      return Object.assign({}, state, { order: action.payload });
    case ADD_COMBO_VARIANTS:
      return Object.assign({}, state, { comboVariants: action.payload });
    case ADD_SHIRT_VARIANTS:
      return Object.assign({}, state, { shirtVariants: action.payload });
    case ADD_YOUTUBE_VIDEO_ARRAY:
      return Object.assign({}, state, { topThreeVideos: action.payload });
    case ADD_SHIPPING_COUNTRIES:
      return Object.assign({}, state, { shippingCountries: action.payload });
    case ADD_SHIPPING_DIVISIONS:
      return Object.assign({}, state, { shippingDivisions: action.payload });
    case ADD_SHIPPING_OPTIONS:
      return Object.assign({}, state, { shippingOptions: action.payload });
    default:
      return state;
  }
};

export default cartReducer;
