import {
  Typography,
  Button,
  Link,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import useStyles from "./checkoutStyles";

const Confirmation = ({ clearError, error }) => {
  const selector = useSelector((state) => state.cartReducer);
  const classes = useStyles();

  if (error) {
    return (
      <>
        <Typography variant="h5">Error: {error}</Typography>
        <br />
        <Button
          component={Link}
          variant="outlined"
          type="button"
          to="/"
          onClick={clearError}
        >
          Back to home
        </Button>
      </>
    );
  }

  return selector.order.customer ? (
    <>
      <div>
        <Typography variant="h5">
          Thank you for your purchase, {selector.order.customer.firstname}{" "}
          {selector.order.customer.lastname}!
        </Typography>
        <Divider className={classes.divider} />
        <Typography variant="subtitle2">
          Order ref: {selector.order.customer_reference}
        </Typography>
      </div>
      <br />
    </>
  ) : (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  );
};

export default Confirmation;
