import React from "react";
import {
  Container,
  Typography,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import CartItem from "./CartItem/CartItem";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useCommerce from "../../hooks/useCommerce";
import { useEffect } from "react";
import cartActions from "../../actions/cartActions";

const Cart = ({ handleUpdateCartQty, handleRemoveFromCart }) => {
  const classes = useStyles();
  const selector = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  const { emptyCart, generateCheckoutToken } = useCommerce(
    localStorage.getItem("cartId"),
    () => {},
    dispatch
  );

  useEffect(() => {
    const fetch = async () => {
      const checkoutTokenId = await generateCheckoutToken();
      if (checkoutTokenId) {
        console.log(checkoutTokenId);
        dispatch(cartActions.addCheckoutToken(checkoutTokenId));
      }
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  if (!selector.cart.line_items)
    return (
      <div className={classes.loadingScreen}>
        <CircularProgress />
      </div>
    );

  const EmptyCart = () => (
    <div className={classes.emptyCartContainer}>
      <Typography className={classes.emptyCartText}>
        You have no items in your shopping cart!
      </Typography>
      <Link to="/shop" className={classes.emptyCartButton}>
        <button>Check out the store!</button>
      </Link>
    </div>
  );

  const FilledCart = () => (
    <>
      <Grid container spacing={3}>
        {selector.cart.line_items.map((item) => (
          <Grid item xs={12} sm={4} key={item.id} className={classes.itemCard}>
            <CartItem
              item={item}
              onUpdateCartQty={handleUpdateCartQty}
              onRemoveFromCart={handleRemoveFromCart}
            />
          </Grid>
        ))}
      </Grid>
      <div className={classes.cardDetails}>
        <Typography className={classes.subtotal}>
          subtotal: {selector.cart.subtotal.formatted_with_symbol}
        </Typography>
        <div>
          <Button
            className={classes.emptyButton}
            size="large"
            type="button"
            variant="contained"
            color="secondary"
            onClick={emptyCart}
          >
            Empty Cart
          </Button>
          <Button
            component={Link}
            to="/checkout"
            className={classes.checkoutButton}
            size="large"
            type="button"
            variant="contained"
            color="primary"
          >
            Checkout
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <Container className={classes.backgroundImage}>
      <div className={classes.toolbar} />
      <Typography className={classes.title}>Your Shopping Cart</Typography>
      {!selector.cart.line_items.length ? <EmptyCart /> : <FilledCart />}
    </Container>
  );
};

export default Cart;
