import { makeStyles } from "@material-ui/core/styles";
import { ivanBlue } from "../../constants/ivanColors";

export default makeStyles((theme) => ({
  navbarContainer: {
    justifyContent: "space-between",
  },
  navbarRight: {
    display: "flex",
    alignItems: "center",
  },
  appBar: {
    background: "white",
    boxShadow: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  title: {
    flexGrow: 1,
    alignItems: "center",
    display: "flex",
    textDecoration: "none",
  },
  grow: {
    flexGrow: 1,
  },
  navbarTabs: {
    paddingRight: 15,
    textDecoration: "none",
    color: ivanBlue,
  },
  hide: {
    color: ivanBlue,
    paddingRight: 15,
    textDecoration: "none",
  },
  button: {
    right: 15,
    color: ivanBlue,
  },
  menuIcon: {
    color: ivanBlue,
  },
  menuIconItem: {
    textDecoration: "none",
    color: ivanBlue,
    fontFamily: "Alphakind",
  },
  menuIconbutton: {
    color: ivanBlue,
  },
}));
